<script setup lang="ts">
import LogoBlack from '~/assets/img/gloda-logo.svg'
import LogoWhite from '~/assets/img/logo-wh.svg'

const props = defineProps<{
  theme?: 'dark' | 'light'
}>()

const links: { [x: string]: { link: string, label: string, isPopover?: boolean, icon?: string }[] } = {
  生态产品: [
    { link: 'https://www.datastory.com.cn/', label: '数说故事' },
    { link: 'https://navigate.datastory.com.cn/', label: '航海加' },
    { link: 'https://www.kaogujia.com/', label: '考古加' },
    { link: 'https://www.kua.ai/', label: 'Kua.ai' },
  ],
  友情链接: [
    { link: 'https://www.tt123.com/', label: 'TT123' },
    { link: 'https://www.tkwosai.com', label: 'TKWOSAI' },
    { link: 'https://www.quickcep.cn/home', label: 'QuickCEP' },
    { link: 'https://www.ziniao.com/?from=Glodastory&utm_source=Glodastory', label: '紫鸟浏览器' },
    { link: 'https://www.zhanfubrowser.com/register?code=FWJM', label: '站斧浏览器' },
    { link: 'https://www.hitoor.com/#/Register?invitecode=NQKBHJ', label: '牛卖浏览器' },
    { link: 'https://www.geelark.cn/?utm_source=useful-links&utm_medium=partner&utm_campaign=Glodastory', label: 'GeeLark' },
    { link: 'http://www.ipidea.net/?utm-source=gs&utm-keyword=?gs', label: 'IPIDEA全球住宅IP' },
    { link: 'http://co.ownips.com/?utm-source=gs&utm-keyword=?gs', label: 'Ownips全球静态IP' },
    { link: 'https://www.liebaotk.com/', label: '猎豹TK' },
    { link: 'https://www.yunlogin.com/', label: '云登指纹浏览器' },
    { link: 'https://luban.bluemediagroup.cn/about/index?utm_source=gloda.vip', label: '鲁班跨境通' },
    { link: 'https://erp.91miaoshou.com/?ac=12to09', label: '妙手ERP' },
    { link: 'https://www.adspolar.com/cn/?source=glada1202', label: 'AdsPolar' },
  ],
  联系我们: [
    { link: '', label: '产品咨询', isPopover: true, icon: 'ds-icon-login-weixin' },
    // { link: '', label: '商务合作' },
  ],
}

const cls = computed(() => props.theme === 'dark' ? 'dark' : '')
</script>

<template>
  <ElFooter :class="cls" style="--el-footer-height: auto; --el-footer-padding: 20px 0 24px" w-1252px>
    <div text-12px font-400 class="text-[var(--color-1)] dark:color-[var(--placeholder-text-color)]">
      <img inline w-227px :src="theme === 'dark' ? LogoWhite : LogoBlack">
      <span ml-8px align-btm class="dark:color-[var(--bg-color)]">TikTok+亚马逊+抖音三合一跨域大数据选品平台</span>
    </div>

    <div mt-24px flex justify-between class="text-[var(--color-1)] dark:color-[var(--placeholder-text-color)]">
      <ElSpace :size="40" alignment="start">
        <div v-for="(_, key) in links" :key="key" flex flex-col>
          <span text-13px font-600 line-height-14px>{{ key }}</span>

          <div max-h-196px flex flex-col flex-wrap gap-x-16>
            <template v-for="(item, i) in links[key]" :key="i">
              <ElPopover
                v-if="item.isPopover"
                trigger="hover"
                placement="right"
                popper-style="--el-popover-border-radius: 12px;"
              >
                <template #reference>
                  <span mt-8px cursor-pointer text="13px [--color-grey-1] dark:[--placeholder-text-color]" font-400 class="hover:text-[var(--color-2)] dark:hover:text-white">
                    {{ item.label }}
                    <i v-if="item.icon" text-14px :class="item.icon" />
                  </span>
                </template>
                <img src="~/assets/img/qiwei-qrcode.png">
              </ElPopover>

              <ElLink
                v-else
                target="_blank"
                :href="item.link"
                style="
                  --el-link-hover-text-color: var(--color-2); --el-link-font-size: 13px;
                  --el-link-font-weight: 400;
                  justify-content: flex-start;
                "
                class="mt-8px text-[var(--color-grey-1)] dark:color-[var(--placeholder-text-color)] dark:hover:text-white"
                :underline="false"
              >
                {{ item.label }}
              </ElLink>
            </template>
          </div>
        </div>
      </ElSpace>

      <ElSpace :size="16" alignment="start">
        <div>
          <div h-116px w-116px flex items-center justify-center border-rd-12px bg-white>
            <img h-100px w-100px src="~/assets/img/gloda-sub-qrcode.png" alt="Gloda订阅号">
          </div>
          <p mt-8px text-center text-13px>
            关注Gloda订阅号
          </p>
        </div>
        <div>
          <div h-116px w-116px flex items-center justify-center border-rd-12px bg-white>
            <img h-100px w-100px src="~/assets/img/gloda-mp-qrcode.png" alt="Gloda小程序">
          </div>
          <p mt-8px text-center text-13px>
            体验Gloda小程序
          </p>
        </div>
      </ElSpace>
    </div>

    <ElDivider style="margin: 24 0;" class="dark:border-[#333]" />

    <section flex items-center justify-end>
      <!-- <img w-154px src="~/assets/img/datastory-logo.png" alt="数说故事logo"> -->
      <span text-12px class="text-[var(--color-grey-1)] dark:color-[var(--placeholder-text-color)]">Copyright © 2024 广州数说故事信息科技有限公司 All Rights Reserved 版权所有 | 备案号：<a href="https://beian.miit.gov.cn/" target="_blank" class="text-[var(--color-grey-1)] dark:color-[var(--placeholder-text-color)]">粤ICP备15073179号-4</a></span>
    </section>
  </ElFooter>
</template>

<style scoped>
</style>
